import React from "react";
import NavHeader from "../navigation/NavHeader";
import AsideLeft from "../aside/AsideLeft";
import AsideRight from "../aside/AsideRight";
import Footer from "../navigation/Footer";
import GoUpDown from "../navigation/GoUpDown";

const MainLayout = (props) => {
    const { currentPage } = props;
    let ads = [];
    if(props.ads){
        ads = [... props.ads];
    }
    return (
        <div className="flex min-h-full flex-col ">
            <NavHeader currentPage={currentPage} />
            <div className=" mx-auto max-w-6xl flex flex-col md:flex-row w-full items-start mt-[28px] md:mt-[19px] gap-x-2 px-[5px] md:px-4 py-[30px] md:py-10 ">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" ads={ads} />
                <main className="flex-1 flex-grow self-stretch flex ">
                    {props.children}
                </main>
                <AsideRight className="hidden md:block"  ads={ads} />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default MainLayout;