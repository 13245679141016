import React, { useState, useEffect } from "react";
import ChevronUpIcon from "../icons/ChevronUp"
import ChevronDownIcon from "../icons/ChevronDown"
import ChatsIcon from "../icons/Chats"
import Pinned from "../icons/Pinned"
import IdentityIcon from "../icons/Identity"
import { Utils } from "../../utils"
import ReactTimeAgo from 'react-time-ago'
import { useNavigate } from "react-router-dom";
import { pinCommunity, unPinCommunity } from "../../core/communities";
import toast from 'react-hot-toast';
import MagnifyingGlassIcon from "../icons/MagnifyingGlass";
import ForumAvatar from "../forum/ForumAvatar";
import Bell from "../icons/Bell";

const CommunityAccordion = ({ community, isOpenInitially, role = "", position = 1, onPinCommunityChanged, communityBudges = {} }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(isOpenInitially);
    const [isPinLoading, setIsPinLoading] = useState(false);
    const [pinErrors, setPinErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [renderedForums, setRenderedForums] = useState([]);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleOpenForum = (e, communityId, forumId) => {
        e.preventDefault();
        navigate(`/community/${communityId}/forum/${forumId}`);
    }

    const handlePinCommunity = async (e, communityId) => {
        e.preventDefault();
        setPinErrors([]);
        setIsPinLoading(true);
        const inputs = {
            id: communityId
        };
        let response = null;
        response = await pinCommunity(inputs);
        setIsPinLoading(false);
        if (response !== true) {
            return setPinErrors(response);
        }
        toast.success('Post was pinned successfully', {
            icon: '✅'
        });
        onPinCommunityChanged();
    }

    const handleUnPinCommunity = async (e, communityId) => {
        e.preventDefault();
        setPinErrors([]);
        setIsPinLoading(true);
        const inputs = {
            id: communityId
        };
        let response = null;
        response = await unPinCommunity(inputs);
        setIsPinLoading(false);
        if (response !== true) {
            return setPinErrors(response);
        }
        toast.success('Post was unpinned successfully', {
            icon: '✅'
        });
        onPinCommunityChanged();
    }

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitSearchForm();
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        submitSearchForm();
    }
    const submitSearchForm = async () => {
        setErrors([]);
        setIsLoading(true);
        const searchBy = searchTerm.trim().toLowerCase();
        if (community && community.forums) {
            let found = [];
            for (let index = 0; index < community.forums.length; index++) {
                const forum = community.forums[index];
                const title = forum.title ? forum.title.trim().toLowerCase() : "";
                if (title.indexOf(searchBy) >= 0) {
                    found.push(forum);
                }
            }
            setRenderedForums(found);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (community && community.forums) {
            setRenderedForums(community.forums);
        }
    }, []);


    return (
        <div className={`flex flex-col  ${role=="admin"?"rounded-t-md": "rounded-b-md"} `}>
            <div
                className={(!isOpen ? `${role=="admin"?"": "rounded-b-md" }` : ` `) + `flex flex-row justify-between accent-bg border-b rounded-t-md border-b-grey-900 text-white px-1 py-2 cursor-pointer`}
                onClick={() => toggleAccordion()} >
                <div className="flex flex-col justify-start items-start  flex-grow">
                    <span className="font-sans text-gray-900 not-italic tracking-tight font-bold text-md leading-[150%] capitalize flex items-center">
                        {role === "admin" &&
                            <span className="mr-1">{position}.</span>
                        }
                        {community?.is_pinned &&
                            <div className="mr-1 text-green-600">
                                <Pinned />
                            </div>
                        }
                        <a className="hover:underline cursor-pointer ">
                            {community?.title}
                        </a>
                    </span>
                    <div className={(community?.is_pinned ? '' : 'pl-0') + ` font-sans text-purple-900  not-italic font-medium text-sm leading-[120%] lowercase  pt-1 text-ellipsis overflow-hidden whitespace-nowrap max-w-[300px]`} >
                        {community?.description}
                    </div>
                </div>
                <div className="cursor-pointer accent-text-color flex ">
                    {isOpen === true && <ChevronUpIcon />}
                    {isOpen === false && <ChevronDownIcon />}
                </div>
            </div>
            {isOpen &&
                <div className={`flex flex-col ${role=="admin"?"rounded-t-md": "rounded-b-md"}`}>
                    

                    {community?.forums?.length > 0 &&
                        <form action="#" method="POST" onSubmit={handleSubmit} className="flex flex-col bg-primary-white flex items-center p-1 py-2" >
                            <div className="relative text-gray-400 focus-within:text-gray-600 w-full md:mx-2" >
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                                    <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />
                                </div>
                                <input
                                    id={`search-community-${community._id}`}
                                    className="block w-full rounded-full border-1 border-gray-200  bg-gray-300 py-0.5 pl-8 pr-3 text-black mb-0  focus:border-purple-900 focus:ring-0 focus:ring-purple-900  md:text-[0.8rem] md:text-md leading-6 disabled:cursor-progress"
                                    placeholder="Search Communities by name"
                                    type="search"
                                    name={`search-community-${community._id}`}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    value={searchTerm}
                                    disabled={isLoading}
                                    aria-label={`search ${community?.title} community input field`}
                                />
                                <button role="button" 
                                    type="submit"
                                    disabled={isLoading}
                                    className="cursor-pointer flex justify-center items-center text-white absolute right-0 border border-purple-900 top-[0px] py-1 bg-purple-800 hover:bg-purple-700 font-sm rounded-r-full text-sm px-4 ">
                                    search
                                </button>
                            </div>
                        </form>
                    }
                    <div className={"max-h-[calc(100vh-38vh)] overflow-scroll show-scrollbars-always bg-slate-100 " + (role=="admin"?"rounded-t-md": "rounded-b-md")} >
                    {renderedForums.map((forum, index) => (
                        <div key={index} onClick={(e) => navigate(`/community/${community._id}/forum/${forum._id}`)} className={`relative cursor-pointer flex flex-col md:flex-row justify-between  border-gray-200 p-3  even:bg-white odd:bg-slate-100 ${role != "admin" ? 'last:rounded-b-md' : ''} `}>

                            <div className="flex flex-row items-center w-full ">
                                <div onClick={(e) => handleOpenForum(e, community._id, forum._id)} className="w-[48px] h-[48px] bg-purple-900x flex justify-center items-center text-purple-300">
                                    <ForumAvatar forum={forum} />
                                </div>
                                <div className="flex-grow flex flex-col justify-center ml-2 gap-y-0 w-full ">
                                    <div className="leading-[0.7]">
                                        <span className="text-sm font-semibold font-medium ">
                                            <a className="hover:underline cursor-pointer "
                                                href={`/community/${community._id}/forum/${forum._id}`}>
                                                {forum.title}
                                            </a>
                                        </span>
                                    </div>
                                    {forum.latest_post &&
                                        <>
                                            <div className="flex text-sm items-center">
                                                <div className="text-xs">
                                                    Latest Post:
                                                </div>
                                                <div className="truncate max-w-[200px] text-purple-900 ml-1">
                                                    <a className="hover:underline cursor-pointer"
                                                        onClick={(e) => { e.stopPropagation(); navigate(`/community/${community._id}/forum/${forum._id}/post/${forum.latest_post.post_id}`) }}
                                                    >
                                                        {forum.latest_post?.title}
                                                    </a>
                                                </div>
                                            </div>


                                            <div className="flex justify-between ">
                                                <div className="flex items-center">
                                                    <span className="text-xs capitalize font-semibold">
                                                        {/* <a
                                                            onClick={(e) => { e.stopPropagation(); navigate(`/profile/${forum.latest_post.user._id}`) }}
                                                            className="hover:underline cursor-pointer">
                                                            {forum.latest_post ? forum.latest_post.user.username + ", " : ""} 
                                                            
                                                        </a>
                                                        */}
                                                        <span className="text-gray-700  text-xxs font-light">{Utils.bigNumberFormat(forum.count_of_posts, 1)} Posts</span>
                                                    </span>
                                                    <span className="text-xs ml-2 capitalize font-semibold">
                                                        {/* <ReactTimeAgo date={forum.latest_post.date_of_post && forum.latest_post.date_of_post.length > 0 ? forum.latest_post.date_of_post : (new Date()).toDateString()} locale="en-US" /> */}
                                                        <span className="text-gray-700 text-xxs font-light">{Utils.bigNumberFormat(forum.count_of_replies, 1)} Comments</span>
                                                    </span>
                                                </div>
                                                <div className="text-muted flex flex-row items-center">

                                                    {/* <IdentityIcon />
                                                    <span className="ml-1 mr-3 text-sm">{Utils.comma(forum.count_of_posts)}</span>
                                                    <ChatsIcon />
                                                    <span className="mx-1 text-sm">{Utils.comma(forum.count_of_replies)}</span> */}
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {!forum.latest_post &&
                                        <>
                                            <div className="flex flex-col text-sm ">
                                                No posts available
                                            </div>

                                            <div className="flex justify-between  ">
                                                <div className="flex items-center">
                                                    <span className="text-xs capitalize ">
                                                        <a href={`/community/${community._id}/forum/${forum._id}`} className="hover:underline cursor-pointer text-purple-900">
                                                            Be the first to post
                                                        </a>
                                                    </span>
                                                    <span className="text-sm ml-2">
                                                        &nbsp;
                                                    </span>
                                                </div>
                                                <div className="text-muted flex flex-row items-center">
                                                    <IdentityIcon />
                                                    <span className="ml-1 mr-3 text-sm">0</span>
                                                    <ChatsIcon />
                                                    <span className="mx-1 text-sm">0</span>
                                                </div>
                                            </div>
                                        </>
                                    }


                                    {/* <div className="text-muted flex flex-row items-center">
                                        <IdentityIcon />
                                        <span className="ml-1 mr-3 text-sm">{Utils.comma(forum.count_of_posts)}</span>
                                        <ChatsIcon />
                                        <span className="mx-1 text-sm">{Utils.comma(forum.count_of_replies)}</span>
                                    </div> */}
                                </div>
                            </div>
                            {forum.latest_post &&
                                <div className="hidden  md:ml-14 mt-2 md:mt-0 md:ml-0 border-t border-[#gray] md:border-0 pt-2 md:pt-0 flex flex-col justify-center items-start">
                                    <div className="flex text-sm">
                                        <div>
                                            Latest Post:
                                        </div>
                                        <div className="truncate max-w-[200px] text-purple-900 ml-1">
                                            <a className="hover:underline cursor-pointer"
                                                href={`/community/${community._id}/forum/${forum._id}/post/${forum.latest_post.post_id}`} >
                                                {forum.latest_post?.title}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="text-xs capitalize font-semibold">
                                            <a className="hover:underline cursor-pointer">
                                                {forum.latest_post ? forum.latest_post.user.username + ", " : ""}
                                            </a>
                                        </span>
                                        <span className="text-sm ml-2">
                                            <ReactTimeAgo date={forum.latest_post.date_of_post && forum.latest_post.date_of_post.length > 0 ? forum.latest_post.date_of_post : (new Date()).toDateString()} locale="en-US" />
                                        </span>
                                    </div>
                                </div>
                            }

                            {(communityBudges[forum._id] && communityBudges[forum._id].length > 0) &&
                                <div className="absolute md:relative text-black text-xs p-1 rounded-sm right-4 md:right-2 md:top-3">
                                    {/* <ChatsIcon /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                    </svg>


                                    <span className={`bg-red-600 max-h-[15px] flex items-center absolute p-1 text-white font-bold  rounded-full  -right-[8px] -top-1`} style={{ fontSize: "12px" }} >
                                        {communityBudges[forum._id].length}
                                    </span>
                                </div>
                            }
                        </div>
                    ))}
                    </div>

                    {renderedForums.length == 0 &&
                        <div className="flex justify-center py-12 text-gray-400 font-semibold rounded-b-md lg:py-20 bg-white rounded-b-md" >
                            <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                <div>
                                    No Matching Forums
                                </div>
                            </div>
                        </div>
                    }
                    
                </div>
            }
            {role === "admin" &&
                <div
                    className="bg-primary-white flex flex-row justify-end border-t border-grey-900  rounded-b-md px-3 py-2"
                >
                    {!community?.is_pinned &&
                        <button role="button" 
                            onClick={(e) => handlePinCommunity(e, community._id)}
                            className="flex items-center justify-center rounded-sm bg-purple-900 hover:bg-purple-800 py-1  mr-2 px-3 text-sm font-semibold leading-6 text-white active:text-white/80 btn-gradient-main cursor-pointer" >

                            Pin
                        </button>
                    }

                    {community?.is_pinned &&
                        <button role="button" 
                            onClick={(e) => handleUnPinCommunity(e, community._id)}
                            className="flex items-center justify-center rounded-sm bg-purple-900 hover:bg-purple-800 py-1  mr-2 px-3 text-sm font-semibold leading-6 text-white active:text-white/80 btn-gradient-main cursor-pointer" >

                            Unpin
                        </button>
                    }

                    <a
                        href={`/community/${community?._id}/forums/add`}
                        className="flex items-center justify-center rounded-sm bg-purple-900 hover:bg-purple-800 py-1  mr-2 px-3 text-sm font-semibold leading-6 text-white active:text-white/80 btn-gradient-main cursor-pointer" >
                        Add Forum
                    </a>

                    <a
                        href={`/community/${community?._id}/edit`}
                        className="flex items-center justify-center rounded-sm bg-purple-900 hover:bg-purple-800 py-1 px-3 text-sm font-semibold leading-6 text-white active:text-white/80 btn-gradient-main cursor-pointer" >
                        Edit
                    </a>
                </div>
            }
        </div>
    );
}
export default CommunityAccordion;